var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Drag and Drop support"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeDragDrop) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Drop mode is enabled by default. It can disabled by setting the ")]), _c('code', [_vm._v("no-drop")]), _c('span', [_vm._v("prop. ")])]), _c('b-form-file', {
    attrs: {
      "placeholder": "Choose a file or drop it here...",
      "drop-placeholder": "Drop file here...",
      "no-drop": ""
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }