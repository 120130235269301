var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "File Input Basic"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeBasic) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_vm._v(" Customized, cross-browser consistent, file input control that supports single file, multiple files, and directory upload (for browsers that support directory mode) ")]), _c('div', [_c('b-form-file', {
    attrs: {
      "placeholder": "Choose a file or drop it here...",
      "drop-placeholder": "Drop file here..."
    },
    model: {
      value: _vm.file,
      callback: function callback($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  }), _c('b-card-text', {
    staticClass: "my-1"
  }, [_vm._v(" Selected file: "), _c('strong', [_vm._v(_vm._s(_vm.file ? _vm.file.name : ''))])]), _c('b-card-text', [_c('span', [_vm._v("You can have ")]), _c('code', [_vm._v("<b-form-file>")]), _c('span', [_vm._v(" render a browser native file input by setting the ")]), _c('code', [_vm._v("plain")]), _c('span', [_vm._v(" prop.")])]), _c('b-form-file', {
    staticClass: "mt-1",
    attrs: {
      "plain": ""
    },
    model: {
      value: _vm.file2,
      callback: function callback($$v) {
        _vm.file2 = $$v;
      },
      expression: "file2"
    }
  }), _c('div', {
    staticClass: "mt-1"
  }, [_vm._v(" Selected file: "), _c('strong', [_vm._v(_vm._s(_vm.file2 ? _vm.file2.name : ''))])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }