var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "File name formatter function"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeFormatter) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Set the prop ")]), _c('code', [_vm._v("file-name-formatter")]), _c('span', [_vm._v(" to a function that accepts a single argument which is an array of File objects.")])]), _c('b-form-file', {
    attrs: {
      "multiple": "",
      "file-name-formatter": _vm.formatNames
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }